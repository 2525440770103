import React, { useState } from 'react';
import Bireysel from './Bireysel/Bireysel';
import TicariPaketler from './Ticari/TicariPaketler';

const Paketler = () => {
    const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: 'Bireysel',
            content: <Bireysel/>
        },
        {
            id: 2,
            tabTitle: 'Ticari',
            content: <TicariPaketler/>
        },
     
    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }
  return (
    <>
     <div className='container'>
     <div>
    {tabs.map((tab, i) =>
        <button
        style={{
            background: currentTab === `${tab.id}` ? 'purple' : '',
            color: currentTab === `${tab.id}` ? 'white' : 'black',
            margin: "5px",
            border: 'none', // Dış çerçeveyi kaldırır
            outline: 'none', // Odaklanıldığında çerçeveyi kaldırır
            boxShadow: currentTab === `${tab.id}` ? '0 0 5px 2px rgba(128,0,128,0.5)' : 'none', // Mor buton için hafif mor gölge
            width:"200px",
            height:"50px"
        }}
        key={i}
        id={tab.id}
        disabled={currentTab === `${tab.id}`}
        onClick={handleTabClick}
        >
        {tab.tabTitle}
        </button>

    )}
</div>

            <div className=''>
                {tabs.map((tab, i) =>
                    <div key={i}>
                        {currentTab === `${tab.id}` && <div><p className='title'>{tab.title}</p><p>{tab.content}</p></div>}
                    </div>
                )}
            </div>
        </div>
    </>
  )
}

export default Paketler