import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Bireysel from './Paketler/Bireysel/Bireysel';
import MusteriListesi from './Musteri/MusteriListesi';
import Paketler from './Paketler/Paketler';

const Form = () => {
  const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: 'ODENİS',
            content: <Paketler/>
        },
        {
            id: 2,
            tabTitle: 'MÜŞTERİLER',
            content: <MusteriListesi/>
        },

    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }


  return (
    <>
      {/* <section className="ftco-section" style={{ marginTop: '-70px' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="tab-menu">
                  <button
                    className={`tab-button ${activeTab === 'ODENİS' ? 'active' : ''}`}
                    onClick={() => handleTabClick('ODENİS')}
                  >
                    ODENİS
                  </button>
                  <button
                    className={`tab-button ${activeTab === 'MusteriListesi' ? 'active' : ''}`}
                    onClick={() => handleTabClick('MusteriListesi')}
                  >
                    Musteri Listesi
                  </button>
                </div>

                {renderTabContent()}
              </div>
            </div>
          </div>
        </div>
      </section> */}
           <div className='container' style={{marginTop:"100px"}}>
            <div className='tabs'>
                {tabs.map((tab, i) =>
                    <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.tabTitle}</button>
                )}
            </div>
            <div className='content'>
                {tabs.map((tab, i) =>
                    <div key={i}>
                        {currentTab === `${tab.id}` && <div><p>{tab.content}</p></div>}
                    </div>
                )}
            </div>
        </div>
    </>
  );
};

export default Form;
