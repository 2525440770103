
import './App.css';
import Form from './component/Form';
import Navbar from "./component/Navbar"
function App() {
  return (
    <div>
      <Navbar/>
      <Form/>
    </div>
  );
}

export default App;
