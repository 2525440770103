import React from 'react';
import logo from '../assets/img/logoDigiturk.svg';

const Navbar = () => {
  return (
    <div style={{ height: '80px', background: 'rgb(91 29 137)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img style={{ width: '130px', height: '100px' }} src={logo} alt="Digiturk" />
    </div>
  );
};

export default Navbar;
