import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
const TicariPaketler = () => {
    const [formData, setFormData] = useState({
        UyeNo: '',
        paketler: '',
        OdemeTuru: '', // Default value for package selection
        Ad: '',
        Soyad: '',
        CepTelefonu: '',
        Sehir: '', // Default value for city selection
        BaslangicTarihi: '',
        BitisTarihi: '',
        ToplamFiyat: '',
        Paket_Durumu: '',
    });
    const resetForm = () => {

        setFormData({
            UyeNo: '',
            paketler: '',
            OdemeTuru: '', // Default value for package selection
            Ad: '',
            Soyad: '',
            CepTelefonu: '',
            Sehir: '', // Default value for city selection
            BaslangicTarihi: '',
            BitisTarihi: '',
            ToplamFiyat: '',
            Paket_Durumu: '',
            Paket_Sure:'',
        });
        setDekontImage(null);
        setPaymentStatus(false);
        setUploadedImages([])
        // Ekstra state'leri sıfırlamak için gerekirse buraya ekleyebilirsiniz
    };
    const [cities, setCities] = useState([]); // State to store the list of cities
    const [paketler, setPaketler] = useState([]);
    const [inputErrorUyeNo, setInputErrorUyeNo] = useState(null);
    const [inputErrorPhone, setInputErrorPhone] = useState(null);
    // Hedef tarihi ayın 3'ü olarak belirle
    const [targetDate, setTargetDate] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [packageID, setPackageID] = useState(0)

    const [dekontImage, setDekontImage] = useState(null);
    const { acceptedFiles: dekontFiles, getRootProps: getDekontRootProps, getInputProps: getDekontInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: newFiles => {
            // Set only the first file in the state
            setDekontImage(newFiles[0]);
        },
    });

    const [uploadedImages, setUploadedImages] = useState([]);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 5,
        onDrop: newFiles => {
            if (uploadedImages.length + newFiles.length > 5) {
                // Display an error message or take any necessary action
                alert("Maksimum 5 tane resim ekleyebilirsiniz")
            } else {
                // Add newFiles to the state if within the limit
                setUploadedImages(prevImages => [...prevImages, ...newFiles]);
            }
        },
    });

    const handleImageRemove = (index) => {
        const updatedImages = [...uploadedImages];
        updatedImages.splice(index, 1);
        setUploadedImages(updatedImages);
    };

    useEffect(() => {
        // Calculate targetDate when the component mounts
        const currentDate = new Date();
        const calculatedTargetDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 3);
        setTargetDate(calculatedTargetDate);

        // Fetch cities
        axios.get('https://musteri.digiturk.az/api/Odenis/SehirleriListele')
            .then(response => {
                setCities(response.data);
            })
            .catch(error => {
                console.error('Error fetching cities:', error);
            });

        // Fetch package list
        axios.get('https://musteri.digiturk.az/api/Odenis/TicariPaketleriListele')
            .then(response => {
                setPaketler(response.data);
            })
            .catch(error => {
                console.error('Error fetching cities:', error);
            });
    }, []); // Empty dependency array triggers the effect only on mount

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [selectedPackageSure, selectedPackageFiyat, selectedPackageID] = value.split('|');
        console.log('id:', name);
        console.log('selectedPackageID:', selectedPackageID);
        console.log('formData:', formData);

        setPackageID(selectedPackageID);

        const isValidInputUyeNo = /^\d{8}$/.test(value);
        const isValidPhoneNumber = /^\d{9}$/.test(value);

        if (name === 'UyeNo') {
            setInputErrorUyeNo(isValidInputUyeNo ? null : 'Üye Numarası 8 rakam olmalıdır.');
        } else if (name === 'CepTelefonu') {
            setInputErrorPhone(isValidPhoneNumber ? null : 'Cep Telefonu 9 rakam olmalıdır ve sadece rakam girilmelidir.');
        }
        if (name === 'paketler') {
            const selectedPaketID = value.split('|')[2];
            localStorage.setItem('selectedPaketID', selectedPaketID);
        }
        setFormData({
            ...formData,
            [name]: selectedPackageSure,
            Paket_Fiyat: selectedPackageFiyat,
            // You can also include Paket_ID in formData if needed
            Paket_ID: selectedPackageID,
            OdemeDurumu: paymentStatus ? 'Alındı' : 'Alınmadı', // Include the payment status
        });
    };




    const handleSubmit = async (e) => {
        e.preventDefault();

        const storedPaketID = localStorage.getItem('selectedPaketID');
        const selectedOdemeTuru = formData.OdemeTuru;
        const selectedPaketDurumu = formData.Paket_Durumu;
        try {
            const maxResimSayisi = 5;
            const imageUploadPromises = uploadedImages.map((image, index) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve({
                            name: `mekan_resim${index + 1}`, // Set the image name
                            data: reader.result.split(',')[1], // Base64-encoded image data
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(image);
                });
            });

            const imagesData = await Promise.all(imageUploadPromises);
            const eksikResimSayisi = maxResimSayisi - imagesData.length;
            if (eksikResimSayisi > 0) {
                for (let i = imagesData.length; i < maxResimSayisi; i++) {
                    imagesData.push({
                        name: `mekan_resim${i + 1}`,
                        data: null, // Eksik resim yerini null olarak ayarla
                    });
                }
            }

            let dekontImageBase64 = null;
            if (dekontImage) {
                const reader = new FileReader();
                reader.onload = () => {
                    dekontImageBase64 = reader.result.split(',')[1];
                    console.log(dekontImageBase64, "dekontImageBase64")
                    const postData = {
                        paket_ID: parseInt(storedPaketID),
                        paket_Hesaplanan_Fiyat: parseFloat(formData.ToplamFiyat),
                        Paket_Sure: parseInt(formData.paketler.split('|')[0]),
                        musteri_Uye_NO: formData.UyeNo,
                        musteri_Ad: formData.Ad,
                        musteri_Soyad: formData.Soyad,
                        musteri_Telefon1: formData.CepTelefonu,
                        musteri_Telefon2: formData.CepTelefonu,
                        musteri_Lokasyon_ID: formData.Sehir,
                        Paket_Odeme_Turu: selectedOdemeTuru,
                        Paket_Odeme_Durumu: paymentStatus === 'alindi' ? 'Odeme Alındı' : 'Odeme Alınmadı',
                        Paket_Dekont: dekontImageBase64,  // Include the Base64-encoded dekont image
                        Paket_Baslangic_Tarihi: formData.BaslangicTarihi,
                        Paket_Bitis_Tarihi: formData.BitisTarihi,
                        Paket_Durum: selectedPaketDurumu,
                        Mekan_Resimler: imagesData.reduce((acc, imgData) => {
                            acc[imgData.name] = imgData.data;
                            return acc;
                        }, {}),
                    };

                    console.log(postData, 'Submit button clicked!');
                    axios.post('https://musteri.digiturk.az/api/Odenis/MusteriTicariOdeme', postData, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(response => {
                            console.log('API Response:', response.data);

                            alert('İşleminiz Başarıyla Gerçekleşti');
                            // Handle success if needed
                            resetForm();
                        })
                        .catch(error => {
                            console.error('API Error:', error);

                            alert('İşleminiz Gerçekleşemedi', error);
                            resetForm();
                            // Handle error if needed
                        });
                };
                reader.readAsDataURL(dekontImage);
            }
        } catch (error) {
            console.error('API Error:', error);
            alert('İşleminiz Gerçekleşemedi', error);
        }
        // Convert the dekont image to a Base64-encoded string

    };

    return (
        <>
            <section className="ftco-section" style={{ marginTop: '-70px' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <h3 className="mb-4">TİCARİ ÖDEME EKRANI</h3>
                                            <form
                                                onSubmit={handleSubmit}
                                                id="contactForm"
                                                name="contactForm"
                                                className="contactForm"
                                            >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">
                                                                <span className="required" style={{ color: "red" }}>*</span>  Üye Numarası:
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${inputErrorUyeNo ? 'is-invalid' : ''}`} name="UyeNo"
                                                                id="UyeNo"
                                                                placeholder="Üye Numarası"
                                                                required
                                                                onChange={handleInputChange}
                                                                value={formData.UyeNo}
                                                            />
                                                            {inputErrorUyeNo && <div className="invalid-feedback">{inputErrorUyeNo}</div>}

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="adi">
                                                                <span className="required" style={{ color: "red" }}>*</span>  Ad:
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Ad"
                                                                id="ad"
                                                                placeholder="Ad"
                                                                required
                                                                onChange={handleInputChange}
                                                                value={formData.Ad}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="soyadi">
                                                                <span className="required" style={{ color: "red" }}>*</span>  Soyad:
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Soyad"
                                                                id="soyad"
                                                                placeholder="Soyad"
                                                                required
                                                                onChange={handleInputChange}
                                                                value={formData.Soyad}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">
                                                                <span className="required" style={{ color: "red" }}>*</span>  PAKETLER
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                name="paketler"
                                                                id={formData.paket_ID}
                                                                onChange={handleInputChange}
                                                                value={formData.Paket_Sure}
                                                            >
                                                                <option value="" disabled selected>
                                                                    Lütfen bir paket seçiniz
                                                                </option>

                                                                {paketler.map(paket => (
                                                                    <option key={paket.Paket_ID} value={`${paket.Paket_Sure}|${paket.Paket_Fiyat}|${paket.Paket_ID}`}>
                                                                        {paket.Paket_Sure} ay
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="soyadi">
                                                                <span className="required" style={{ color: "red" }}>*</span>  Ödeme Türü:
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                name="OdemeTuru"
                                                                id="odemeTuru"
                                                                onChange={handleInputChange}
                                                                value={formData.OdemeTuru}
                                                            >
                                                                <option value="" disabled selected>
                                                                    Lütfen bir ödeme şekli seçiniz
                                                                </option>
                                                                <option value="Nakit">
                                                                    Nakit
                                                                </option>
                                                                <option value="Kart">
                                                                    Kredi Kartı
                                                                </option>
                                                                <option value="Kapital Banka">
                                                                    Kapital Banka
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">
                                                                <span className="required" style={{ color: "red" }}>*</span>  CEP TELEFON NUMARASI:
                                                            </label>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">+994</span>
                                                                </div>
                                                                <input
                                                                    type="number"
                                                                    className={`form-control ${inputErrorPhone ? 'is-invalid' : ''}`}
                                                                    name="CepTelefonu"
                                                                    id="cepTelefonu"
                                                                    placeholder=" Cep Telefon Numarası (555555555)"
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={formData.CepTelefonu}

                                                                />
                                                                {inputErrorPhone && <div className="invalid-feedback">{inputErrorPhone}</div>}                          </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">
                                                                <span className="required" style={{ color: "red" }}>*</span> Şehir
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                name="Sehir"
                                                                id="sehir"
                                                                onChange={handleInputChange}
                                                                value={formData.Sehir}
                                                            >
                                                                <option value="" disabled selected>
                                                                    Lütfen bir şehir seçiniz
                                                                </option>

                                                                {cities.map(city => (
                                                                    <option key={city.Lokasyon_ID} value={city.Lokasyon_ID}>
                                                                        {city.Sehir}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="ToplamFiyat">
                                                                <span className="required" style={{ color: "red" }}>*</span>  Toplam Tutar (₼):
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="ToplamFiyat"
                                                                id="ToplamFiyat"
                                                                placeholder="Toplam Fiyat"
                                                                required
                                                                onChange={handleInputChange}
                                                                value={formData.ToplamFiyat}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="BaslangicTarihi">
                                                                <span className="required" style={{ color: "red" }}>*</span>  Aktivasyon Tarihi:
                                                            </label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="BaslangicTarihi"
                                                                id="BaslangicTarihi"
                                                                placeholder="Aktivasyon Tarihi"
                                                                required
                                                                onChange={handleInputChange}
                                                                value={formData.BaslangicTarihi}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="BitisTarihi">
                                                                <span className="required" style={{ color: "red" }}>*</span>  Bitiş Tarihi:
                                                            </label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="BitisTarihi"
                                                                id="BitisTarihi"
                                                                placeholder="Bitiş Tarihi"
                                                                required
                                                                onChange={handleInputChange}
                                                                value={formData.BitisTarihi}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="odemeDurumu">
                                                                <span className="required" style={{ color: "red" }}>*</span> Ödeme Durumu:
                                                            </label>
                                                            <br />
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="odemeDurumu"
                                                                    id="odemeAlindi"
                                                                    value="alindi"
                                                                    checked={paymentStatus === 'alindi'}
                                                                    onChange={() => setPaymentStatus('alindi')}

                                                                />
                                                                <label className="form-check-label" htmlFor="odemeAlindi">
                                                                    Ödeme Alındı
                                                                </label>
                                                            </div>

                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="odemeDurumu"
                                                                    id="odemeAlinmadi"
                                                                    value="alinmadi"
                                                                    checked={paymentStatus === 'alinmadi'}
                                                                    onChange={() => setPaymentStatus('alinmadi')}
                                                                />
                                                                <label className="form-check-label" htmlFor="odemeAlinmadi">
                                                                    Ödeme Alınmadı
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="Paket_Durumu">
                                                                <span className="required" style={{ color: "red" }}>*</span> Paket Durumu:
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                name="Paket_Durumu"
                                                                id="Paket_Durumu"
                                                                onChange={handleInputChange}
                                                                value={formData.Paket_Durumu}
                                                            >
                                                                <option value="" disabled selected>
                                                                    Lütfen bir paket durumu seçiniz
                                                                </option>
                                                                <option value="Aktif">Aktif</option>
                                                                <option value="Pasif">Pasif</option>
                                                                <option value="Beklemede">Beklemede</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div {...getRootProps()} className="dropzone">
                                                                <input {...getInputProps()} />
                                                                <button type="button" className="btn btn-primary">
                                                                    {uploadedImages.length === 0 ? 'Mekan Resmi Ekle' : 'Daha Fazla Mekan Resim Ekle'}
                                                                </button>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div {...getDekontRootProps()} className="dropzone">
                                                                <input {...getDekontInputProps()} />
                                                                <button type="button" className="btn btn-primary">
                                                                    {dekontImage ? 'Dekont Resmi Eklendi' : 'Dekont Resmi Ekle'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group" >
                                                            <input
                                                                type="submit"
                                                                value="Kaydet"
                                                                className="btn btn-success"
                                                            />
                                                            <div className="submitting"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <section className="ftco-section" style={{ marginTop: "-270px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            {uploadedImages.length > 0 && (
                                                <>
                                                    <h3 className="mb-4">Mekan Görüntüleri</h3>
                                                    <div className="row">
                                                        {uploadedImages.map((file, index) => (
                                                            <div key={index} className="col-md-2 mb-3">
                                                                <div className="uploaded-image d-flex flex-column align-items-center">
                                                                    <img
                                                                        src={URL.createObjectURL(file)}
                                                                        alt={`uploaded-${index}`}
                                                                        className="uploaded-preview img-fluid"
                                                                    />
                                                                    <p className="text-center">Mekan {index + 1}</p>
                                                                    <FontAwesomeIcon
                                                                        style={{ color: "red" }}
                                                                        onClick={() => handleImageRemove(index)}
                                                                        icon={faTrashAlt}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            {dekontImage && (
                                                <>
                                                    <h3 className="mb-4">Dekont Resmi</h3>
                                                    <div className="row">
                                                        <div className="uploaded-image d-flex flex-column align-items-center">
                                                            <img
                                                                src={URL.createObjectURL(dekontImage)}
                                                                alt="uploaded-dekont"
                                                                className="uploaded-preview img-fluid"
                                                            />
                                                            <p className="text-center">Dekont Resmi</p>
                                                            <FontAwesomeIcon
                                                                style={{ color: "red" }}
                                                                onClick={() => setDekontImage(null)}
                                                                icon={faTrashAlt}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TicariPaketler